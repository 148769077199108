.clickable-table-row {
  cursor: pointer;
}

.faded-table-row {
  color: #b0b0b0;
  background: rgba(250, 250, 250, 0.5);
}

.ant-table-filter-column .anticon-search {
  font-size: 16px;
  font-weight: 500;
}

.ant-table-filter-column .anticon-filter {
  font-size: 16px;
  font-weight: 500;
}

.ant-table-column-sorter-inner .ant-table-column-sorter-up {
  font-size: 14px;
}

.ant-table-column-sorter-inner .ant-table-column-sorter-down {
  font-size: 14px;
}

.ant-table-filter-trigger-container {
  background-color: #e8edff;
  width: 40px;
}

.ant-table-filter-trigger-container .ant-table-filter-trigger {
  font-size: 14px;
}
